<template>
  <div>
    <a-card :title="$t('系统配置')">
      <a-spin :spinning="loading">
        <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
          <a-row>
            <!-- <a-col :span="24">
              <a-form-item :label="$t('是否质检')">
                <a-radio-group
                  v-decorator="['enable_quality_inspection']"
                  button-style="solid"
                  style="width: 100%; text-align: center"
                >
                  <a-radio-button :value="true" style="width: 50%"> {{ $t("开启") }} </a-radio-button>
                  <a-radio-button :value="false" style="width: 50%"> {{ $t("关闭") }} </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col> -->
            <a-col :span="12">
              <a-form-item :label="$t('是否计费')">
                <a-radio-group v-decorator="['enable_charge']" button-style="solid" style="width: 100%; text-align: center">
                  <a-radio-button :value="true" style="width: 50%"> {{ $t("开启") }} </a-radio-button>
                  <a-radio-button :value="false" style="width: 50%"> {{ $t("关闭") }} </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :label="$t('到期时间')">
                <a-input :value="$store.state.user.expiryTime" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item :label="$t('首页配置')" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                <HomeConfig v-decorator="['home_config']" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm :title="$t('确定保存吗?')" :loading="confirmLoading" @confirm="create">
          <a-button type="primary" :loading="confirmLoading">{{ $t("保存") }}</a-button>
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import { systemConfigsList, systemConfigsSave } from "@/api/system";

export default {
  name: "SystemConfigs",
  components: {
    HomeConfig: () => import("./HomeConfig"),
  },
  data() {
    return {
      loading: false,
      visible: false,
      form: this.$form.createForm(this),
      confirmLoading: false,
    };
  },
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      systemConfigsList()
        .then((data) => {
          console.log(data);
          this.$nextTick(() => {
            this.form.setFieldsValue({
              enable_quality_inspection: data.enable_quality_inspection,
              enable_charge: data.enable_charge,
              home_config: data.home_config,
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true;
          console.log(values);
          systemConfigsSave(values)
            .then(() => {
              this.$message.success(this.$t("保存成功"));
              this.$router.go(0);
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
